<script setup lang="ts">
import { SmallProps } from "./Small.props"
withDefaults(defineProps<SmallProps>(), {
  theme: "white",
  isCardFirst: false,
  isSponsored: false
})

const dynamicId = computed(() => `button-${Math.random()}`)

const triggerLink = () => document.getElementById(dynamicId.value)?.click()
</script>

<template>
  <div
    class="small-brand-block-wrapper flex flex-col justify-between gap-4 p-4"
    :class="{
      'bg-gradient-to-r from-green-main to-tiffany-30': theme === 'green-10',
      'bg-gradient-to-r from-[#F9B058] to-[#FACA8F]': theme === 'orange-10',
      'flex-col-reverse': isCardFirst,
      'md:grid-standard': !isAlwaysVertical
    }"
  >
    <div
      class="rounded-lg px-4 py-2"
      :class="[
        `bg-${theme}`,
        {
          'md:col-span-8 md:flex md:flex-row-reverse md:justify-between md:p-0 md:pl-10 md:pt-8':
            !isAlwaysVertical
        }
      ]"
    >
      <div class="relative shrink-[0.5]">
        <UtilsWrapperImage
          v-if="brandImage"
          v-bind="brandImage"
          :altText="title"
          :titleText="title"
          class="wrapper-image-full-container-centered cursor-pointer"
          :class="{
            'md:overflow-hidden md:rounded-br-lg': !isAlwaysVertical
          }"
          sizes="sm:400px md:400px lg:400px"
          @click="triggerLink"
        />
      </div>

      <div
        class="mt-2 flex flex-col justify-between gap-2 md:mt-0 md:pb-8"
        :class="{ 'md:gap-0': !isAlwaysVertical }"
      >
        <section>
          <span
            v-if="brandTitle"
            class="mouse-bold md:beaver-bold uppercase text-black-80"
          >
            {{ brandTitle }}
          </span>

          <p v-if="title" class="dolphin-bold md:mammoth-bold">
            {{ title }}
          </p>
        </section>
        <UtilsButton
          v-if="cta && cta.text"
          class="cursor-pointer"
          :class="{
            'md:custom-btn-no-padding md:custom-btn-start md:mt-4':
              !isAlwaysVertical
          }"
          theme="ghost-green"
          v-bind="cta"
          order="last"
          :id="dynamicId"
        >
          <template #icon>
            <UtilsIcon
              name="ArrowRight.svg"
              color="green-main"
              class="h-5 w-5"
            />
          </template>
        </UtilsButton>
      </div>
    </div>

    <GAList
      id="product-brand-block"
      :name="`Product Brand: ${brandTitle}`"
      entity="productBrandBlock"
    >
      <ProductTileOverallResults
        :class="{
          'md:col-span-4': !isAlwaysVertical,
          'md:col-start-1 md:row-start-1': !isAlwaysVertical && isCardFirst
        }"
        v-if="card"
        v-bind="card"
        class="w-auto pt-8"
      />
    </GAList>
  </div>
</template>
